import './App.css';

function App() {
  return (

      <div class="tm_class">
          <iframe class="tm_frame" seamless aria-hidden="true" src="https://ticket-stream.streamlit.app/?embedded=true" title="Ticketmaster Event Search Application" frameBorder="0" allow="fullscreen"  width="100%" height="950px">
          </iframe>
      </div>

  );
}

export default App;
